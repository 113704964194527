import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Sheet,
  Input,
  Button,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import SupportSystem from '../support/SupportSystem';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { useAuth } from '../../context/AuthContext';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import { useBalance } from '../../context/BalanceContext';

function TrcWithdrawal() {
  const { user } = useAuth();
  const [selectedModalOption, setSelectedModalOption] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const { latestBalance } = useBalance();
  const [investmentPayments, setInvestmentPayments] = useState([]);
  const [formData, setFormData] = useState({
    amount: '',
    wallet: '',
  });
  const [cryptoAddress, setCryptoAddress] = useState('');
  const [error, setError] = useState('');

  console.log('userSnehangs', latestBalance);

  useEffect(() => {
    // Fetch crypto data from Firestore
    const fetchCryptoData = async () => {
      try {
        const cryptoCollection = collection(db, 'cryptoAddresses'); // Collection name in Firestore
        const q = query(cryptoCollection, where('uid', '==', user.uid)); // Query to find matching UID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const cryptoDoc = querySnapshot.docs[0].data(); // Get the first matching document
          setCryptoAddress(cryptoDoc.cryptoAddress); // Set the crypto address
        } else {
          setError('No matching crypto address found for this user.');
        }
      } catch (err) {
        console.error('Error fetching crypto data:', err);
        setError('Failed to fetch crypto address. Please try again.');
      }
    };

    const fetchInvestmentPayments = async () => {
      try {
        const investmentPaymentsCollection = collection(
          db,
          'investmentPayments'
        );
        const q = query(
          investmentPaymentsCollection,
          where('memberUId', '==', user.uid)
          // where('withdrawalStatus', '==', 'no')
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const paymentsData = querySnapshot.docs.map((doc) => doc.data());

          setInvestmentPayments(paymentsData);

          const totalApprovedAmount = paymentsData.reduce(
            (total, payment) => total + parseFloat(payment.payableAmount),
            0
          );
          console.log('totalApprovedAmount', totalApprovedAmount);

          setWalletBalance(totalApprovedAmount);
        } else {
          setError('No matching investment payments found for this user.');
        }
      } catch (err) {
        console.error('Error fetching investment payments:', err);
        setError('Failed to fetch investment payments. Please try again.');
      }
    };

    fetchCryptoData();
    fetchInvestmentPayments();
    fetchCryptoData();
  }, [user.uid, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleWithdrawalRequest = async () => {
    if (formData.amount > latestBalance) {
      toast.error('Withdrawal amount exceeds wallet balance.');
      return;
    }

    try {
      const investmentPaymentWithdrawalCollection = collection(
        db,
        'investmentPaymentWithdrawal'
      );
      await addDoc(investmentPaymentWithdrawalCollection, {
        memberUId: user.uid,
        memberId: investmentPayments[0].memberBVid,
        memberName: investmentPayments[0].memberName,
        amount: formData.amount,
        type: 'credit',
        message: 'Withdraw Request USDT(TRC20)',
        withdrawalStatus: 'pending',
        timestamp: new Date(),
        wallet: formData.wallet,
      });
      toast.success('Withdrawal request submitted successfully.');
      setFormData({
        amount: '',
        wallet: '',
      });
    } catch (err) {
      console.error('Error submitting withdrawal request:', err);
      toast.error('Failed to submit withdrawal request. Please try again.');
    }
  };

  const handleSlotChange = (slot) => {
    console.log('Selected slot:', slot);
  };

  //button enable and desabler
  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    if ([10, 11, 12, 13].includes(currentDay)) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, []);

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: '100vh',
          position: 'relative', // Required for positioning children
          overflow: 'hidden', // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />

        <Typography
          level="h2"
          style={{
            color: '#FFFFFF',
            marginTop: 30,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
          fontWeight="lg"
        >
          TRC20 Withdrawal Request
        </Typography>
        <Typography 
          level="h2"
        style={{ color: '#031633' }}>
          Wallet Balance:<span style={{ color: 'red' }}>{latestBalance}</span>{' '}
        </Typography>

        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: 'sm',
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
            backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
            boxShadow: 'none',
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl sx={{ flex: 1, minWidth: '200px' }}>
              <FormLabel style={{ color: 'black' }}>
                Available Balance :
              </FormLabel>
              <Input
                value={latestBalance}
                placeholder="$34.57 USD"
                readOnly
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Wallet :</FormLabel>
              <select
                name="wallet"
                id="timeSlot"
                className="px-2 py-2 border rounded bg-transparent border-gray-600"
                value={formData.wallet}
                style={{ backgroundColor: '#1a2a339c', color: 'white' }}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Your Wallet
                </option>
                <option value="TRC20">TRC20</option>
                <option value="BEP20">BEP20(BSC)</option>
              </select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>
                Amount to Withdraw :
              </FormLabel>
              <Input
                name="amount"
                placeholder="$34.57 USD"
                value={formData.amount}
                onChange={handleInputChange}
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Address :</FormLabel>
              <Input
                placeholder="Set your Crypto Address"
                value={cryptoAddress}
                readOnly
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="solid"
              color="success"
              onClick={handleWithdrawalRequest}
              disabled={!isButtonEnabled}
              sx={{ width: '50%' }}
            >
              Continue
            </Button>
            </Box>
            <Typography sx={{ color: 'red', marginTop: 2, textAlign: 'center' }}>
            Each 10th, 11th, 12th and 13th day of every month will open.
            </Typography>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default TrcWithdrawal;
