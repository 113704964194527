import React, { useState, useEffect } from 'react';
import { CssVarsProvider, Box, Typography, Button, Input, CircularProgress, FormControl, FormLabel } from '@mui/joy';
import { db } from '../../firebase'; // Import Firebase setup
import { collection, getDocs, addDoc, setDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext'; // Assuming you have an AuthContext
import framesxTheme from '../../theme';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import * as Yup from 'yup';

function InvestmentByAdmin() {
  const { user } = useAuth();
  const validationSchema = Yup.object().shape({
      // mobileNumber: Yup.string()
      //   .matches(/^\d{10}$/, "Invalid mobile number")
      //   .required("Mobile No. is required"),
      // email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required('Password is required'),
      investmentAmount: Yup.number()
        .typeError('Investment Amount must be a number')
        .positive('Investment Amount must be positive')
        .required('Investment Amount is required'),
      brokerId: Yup.string().required('Broker Name is required'),
      paymentModeId: Yup.string().required('Payment Mode is required'),
      mt5Id: Yup.string().notRequired(),
      mt5Password: Yup.string().notRequired(),
      tModeType: Yup.string().required(),
    });
    const storage = getStorage();
      const [errors, setErrors] = useState({});
  const [members, setMembers] = useState([]);
    const [isValid, setIsValid] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [selectedMemberData, setSelectedMemberData] = useState(null);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    mobileNumber: '',
    email: '',
    password: '',
    investmentAmount: 0,
    investmentdate: '',
    brokerId: '',
    paymentModeId: '',
    md5Userid: '',
    mt5Password: '',
    tModeType: '',
    imageUrl: '',
  });

  const paymentTypes = [
    { paymentModeId: '1', paymentModeType: 'Cash' },
    { paymentModeId: '2', paymentModeType: 'TRC20' },
    // { paymentModeId: '3', paymentModeType: 'Card' },
  ];

  const types = [
    { typeModeId: '1', tModeType: 'IB' },
    { typeModeId: '2', tModeType: 'Investment' },
  ];

console.log(members, selectedMemberData);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersCollection = collection(db, 'members');
        const membersSnapshot = await getDocs(membersCollection);
        const membersData = membersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMembers(membersData);
        setLoadingMembers(false);
      } catch (error) {
        console.error('Error fetching members: ', error);
        toast.error('Error fetching members.');
        setLoadingMembers(false);
      }
    };

    fetchMembers();
  }, []);

  const handleMemberSelect = (e) => {
    const memberId = e.target.value;
    setSelectedMemberId(memberId);
    const memberData = members.find(member => member.id === memberId);
    setSelectedMemberData(memberData);
  };
  
    const handleInputChange = async (event) => {
      const { name, value, files } = event.target;
  
      if (name === 'image') {
        // If an image file is selected, upload it to Firebase Storage
        const file = files[0];
        if (file) {
          try {
            const imageRef = ref(storage, `images/${file.name}`);
            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            setFormData((prevData) => ({ ...prevData, imageUrl: downloadURL })); // Update formData with the image URL
            toast.success('Image uploaded successfully!');
          } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image');
          }
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const requestData = {
        ...formData,
        createdBy: selectedMemberData.name,
        createdByBvId: selectedMemberData.BVID,
        createdUserId: selectedMemberData.memberuid,
        createdAt: new Date().toISOString(),
        status: 'Pending',
        ActiveStatus: 'In-Active',
      };
console.log(requestData); 

      // // Save the same data in the fundBalance collection
      const fundBalanceDocRef = doc(collection(db, 'fundBalance'));
      await setDoc(fundBalanceDocRef, requestData);

      // Save the data in the liveAccountRequests collection
      await addDoc(collection(db, 'liveAccountRequests'), requestData);

      toast.success('Form submitted successfully!');
      setFormData({
        mobileNumber: '',
        email: '',
        password: '',
        investmentAmount: 0,
        investmentdate: '',
        brokerId: '',
        paymentModeId: '',
        md5Userid: '',
        mt5Password: '',
        tModeType: '',
        imageUrl: '',
      }); // Reset form
      setSelectedMemberId(''); // Clear selected member ID
      setSelectedMemberData(null); // Clear selected member data
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.message || 'Submission failed!');
    } finally {
      setIsSubmitting(false); // Enable the submit button
    }
  };

   // Effect to check form validity
    useEffect(() => {
      validationSchema.isValid(formData).then((valid) => {
        setIsValid(valid);
      });
    }, [formData, validationSchema]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', padding: '1rem' }}>
        <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: 'black',
              textAlign: 'left',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              marginTop: 6,
            }}
          >
            Investment
          </Typography>
          {loadingMembers ? (
            <CircularProgress />
          ) : (
            <div>
              <Typography level="h4">Select Member</Typography>
              <select
                value={selectedMemberId}
                required
                onChange={handleMemberSelect}
                style={{ marginBottom: '1rem', width: '100%', padding: '8px' }}
              >
                <option value="">Select a member</option>
                {members.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name} ({member.BVID})
                  </option>
                ))}
              </select>
              <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Payment Mode:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="paymentModeId"
                    value={formData.paymentModeId}
                    onChange={handleInputChange}
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  >
                    <option value="">Select Payment Mode</option>
                    {paymentTypes.map((type) => (
                      <option
                        key={type.paymentModeId}
                        value={type.paymentModeType}
                      >
                        {type.paymentModeType}
                      </option>
                    ))}
                  </select>
                  {errors.paymentModeId && (
                    <Typography color="error" variant="body2">
                      {errors.paymentModeId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Select Image:</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.imageUrl && (
                    <Typography color="error">{errors.imageUrl}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Amount (USDT):</FormLabel>
                  <Input
                    name="investmentAmount"
                    value={formData.investmentAmount}
                    onChange={handleInputChange}
                    required
                    type="number"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Date:</FormLabel>
                  <Input
                    name="investmentdate"
                    value={formData.investmentdate}
                    onChange={handleInputChange}
                    required
                    type="date"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Broker Name:</FormLabel>
                  <input
                    type="text"
                    className="px-2 py-2 border rounded bg-transparent border-white-600"
                    name="brokerId"
                    value={formData.brokerId}
                    onChange={handleInputChange}
                    placeholder="Enter Broker Name"
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.brokerId && (
                    <Typography color="error" variant="body2">
                      {errors.brokerId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    type="password"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.password && (
                    <Typography color="error" variant="body2">
                      {errors.password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Id. (optional):</FormLabel>
                  <Input
                    name="md5Userid"
                    value={formData.md5Userid}
                    onChange={handleInputChange}
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.mt5Id && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Id}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Password. (optional):</FormLabel>
                  <Input
                    name="mt5Password"
                    value={formData.mt5Password}
                    onChange={handleInputChange}
                    type="password"
                    style={{ background: '#1a2a3385', color: 'white' }}
                  />
                  {errors.mt5Password && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Type:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="tModeType"
                    value={formData.tModeType}
                    onChange={handleInputChange}
                    required
                    style={{ background: '#1a2a3385', color: 'white' }}
                  >
                    <option value="">Select Type</option>
                    {types.map((t) => (
                      <option key={t.typeModeId} value={t.tModeType}>
                        {t.tModeType}
                      </option>
                    ))}
                  </select>
                  {errors.tModeType && (
                    <Typography color="error" variant="body2">
                      {errors.tModeType}
                    </Typography>
                  )}
                </FormControl>
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  sx={{ marginTop: 2 }}
                  disabled={!isValid} // Disable button if form is not valid
                >
                  Submit Request
                </Button>
              </Box>
            </form>
            </div>
          )}
        </Box>
      </CssVarsProvider>
    </div>
  );
}

export default InvestmentByAdmin;