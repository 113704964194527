import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../firebase'; // Firebase configuration import
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

function InvestmentPaymentReport() {
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState(''); // default to 'fixed'
  const [paymentAmount, setPaymentAmount] = useState(0); // payment amount input state
  const [paymentDate, setPaymentDate] = useState(''); // payment date input state
  const tableRef = useRef();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [memberBVid, setMemberBVid] = useState('');
  console.log('settlements', settlements, leaders);

  const [selectedItems, setSelectedItems] = useState([]);
  console.log('selectedItems', selectedItems, startDate, endDate, memberBVid);

  // Fetching the settlements from Firestore
  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const q = query(collection(db, 'investmentPayments'));
        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter data based on date range
        const filteredData = settlementsData.filter((settlement) => {
          const settlementDate = new Date(settlement.timestamp.seconds * 1000);
          return (
            (!startDate || new Date(startDate) <= settlementDate) &&
            (!endDate || new Date(endDate) >= settlementDate)
          );
        });

        const filteredByDate = settlementsData.filter((settlement) => {
          const settlementDate = new Date(settlement.timestamp.seconds * 1000);
          return (
            (!startDate || new Date(startDate) <= settlementDate) &&
            (!endDate || new Date(endDate) >= settlementDate)
          );
        });

        // Filter data by selected leader name
        const filteredByLeader = selectedLeader
          ? filteredByDate.filter(
              (settlement) => settlement.leaderName == selectedLeader
            )
          : filteredByDate;

        // Filter data by payment status
        const filteredByStatus = paymentStatus
          ? filteredByLeader.filter(
              (settlement) => settlement.paymenystatus === paymentStatus
            )
          : filteredByLeader;

        // Extract unique leader names
        const leaderNames = [
          ...new Set(filteredByDate.map((settlement) => settlement.leaderName)),
        ];

        setSettlements(filteredByStatus);
        setLeaders(leaderNames);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    fetchSettlements();
  }, [startDate, endDate, selectedLeader, paymentStatus]); 

  // useEffect(() => {
  //   const fetchSettlements = async () => {
  //     try {
  //       let q = query(collection(db, 'investmentPayments'));

  //       if (startDate) {
  //         q = query(q, where('date', '>=', new Date(startDate)));
  //       }
  //       if (endDate) {
  //         q = query(q, where('date', '<=', new Date(endDate)));
  //       }
  //       if (memberBVid) {
  //         q = query(q, where('memberBVid', '==', memberBVid));
  //       }

  //       const querySnapshot = await getDocs(q);
  //       const settlementsData = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setSettlements(settlementsData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching data: ', error);
  //       toast.error('Error fetching data.');
  //       setLoading(false);
  //     }
  //   };

  //   fetchSettlements();
  // }, [startDate, endDate, memberBVid]);


  // Helper function to calculate balance based on fixed amount or percentage
  // const calculateBalance = (approvedAmount, percentage, remainingBalance) => {
  //   console.log('snehangshu', remainingBalance);

  //   if (isNaN(approvedAmount) || isNaN(percentage)) return 0;

  //   if (paymentType === 'fixed') {
  //     // If fixed payment type, subtract the fixed amount from approvedAmount
  //     return approvedAmount - paymentAmount;
  //   } else if (paymentType === 'percentage') {
  //     // If percentage payment type, calculate the deducted amount based on percentage
  //     return approvedAmount - (approvedAmount * percentage) / 100;
  //   }
  //   return 0;
  // };
  const calculateBalance = (approvedAmount, percentage, remainingBalance) => {
    console.log(
      'Remaining Balance:',
      remainingBalance,
      approvedAmount,
      percentage
    );

    if (isNaN(approvedAmount) || isNaN(percentage) || isNaN(remainingBalance)) {
      return 0;
    }

    if (remainingBalance > 0) {
      // If remaining balance exists
      if (paymentType === 'fixed') {
        // Subtract fixed payment amount from remaining balance
        return remainingBalance - paymentAmount;
      } else if (paymentType === 'percentage') {
        // Calculate payment amount based on percentage and subtract from remaining balance
        const givenBalance = (approvedAmount * percentage) / 100;
        console.log('givenBalance', givenBalance);

        return remainingBalance - givenBalance;
      }
    } else {
      // If no remaining balance, calculate normally based on paymentType
      if (paymentType === 'fixed') {
        // Subtract fixed payment amount from approved amount
        return approvedAmount - paymentAmount;
      } else if (paymentType === 'percentage') {
        // Calculate payment amount based on percentage
        return approvedAmount - (approvedAmount * percentage) / 100;
      }
    }
    return 0; // Default fallback
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      for (const settlement of settlements) {
        console.log('settlement', settlements);

        // Calculate the new balance based on payment type
        const newBalance = calculateBalance(
          settlement.approvedamount,
          settlement.percentage,
          settlement.remainingBalance
        );
        const paymenystatus = selectedItems.includes(settlement.id)
          ? 'not-take-payment'
          : 'take-payment';

        console.log('Updating Settlement:', paymenystatus);

        // Update Firestore with the calculated balance
        await updateSettlementBalance(
          settlement.id,
          newBalance,
          paymentAmount,
          paymenystatus
        );
        // Add the items to the oldsettlementPayment collection
        await addSettlementPayment(
          settlement.id,
          newBalance,
          paymenystatus,
          settlement
        );
      }

      toast.success('All settlements updated successfully!');
    } catch (error) {
      console.error('Error updating data: ', error);
      toast.error('Error updating settlements.');
    } finally {
      setLoading(false);
    }
  };

  const updateSettlementBalance = async (
    settlementId,
    newBalance,
    paymentAmount,
    paymenystatus
  ) => {
    try {
      const settlementRef = doc(db, 'oldSettlement', settlementId);
      await updateDoc(settlementRef, {
        remainingBalance: newBalance, // Update remaining balance
        givenBalance: paymentAmount,
        paymenystatus,
      });
      console.log(
        'Updated Settlement ID:',
        settlementId,
        'with New Balance:',
        newBalance
      );
    } catch (error) {
      console.error('Error updating data: ', error);
      toast.error('Error updating settlement with ID: ' + settlementId);
    }
  };

  const addSettlementPayment = async (
    settlementId,
    newBalance,
    paymenystatus,
    settlement
  ) => {
    try {
      await addDoc(collection(db, 'oldsettlementPayment'), {
        ...settlement,
        givenBalance: newBalance,
        paymenystatus, // Add status
        timestamp: new Date(), // Add timestamp
      });
      console.log(
        'Added Settlement Payment for ID:',
        settlementId,
        'with New Balance:',
        newBalance,
        'and Status:',
        paymenystatus
      );
    } catch (error) {
      console.error('Error adding data: ', error);
    }
  };

  const handlePrint = () => {
    const printContent = tableRef.current.innerHTML;
    const message = `
     <div class="header-message">
  <div class="header-title">Investment Interest Payment for January</div> 
  <div class="header-underline"></div>
  <div>
    As per our agreement, we confirm the distribution of the investment interest payment for January. The funds are disbursed based on the agreed terms, and we appreciate your acknowledgment of this payment.
  </div>
</div>
    `;

    const tableHeader = `
      <thead>
        <tr>
          <th>SL. No</th>
          <th>Date</th>
          <th>Name</th>
          <th>Old ID</th>
          <th>Recommended Leader Name</th>
          <th>Balance</th>
          <th>Approved Amount</th>
          <th>Payable Amount</th>
          <th>Remaining Balance</th>
          <th>Signature</th>
        </tr>
      </thead>
    `;

    const newWindow = window.open('', '_blank', 'width=800,height=600');
    newWindow.document.write(`
      <html>
        <head>
          <title>LIST OF PAYOUT OF JANUARY'25</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              color: black;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              color: black;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
              color: black;
            }
            th {
              background-color: #555;
              color: white;
            }
            .header-message {
              text-align: center;
              margin-bottom: 20px;
            }
            .header-title {
              font-size: 100%; /* Adjust font size */
              font-weight: bold;
            }
            .header-underline {
              width: 100%; /* Adjust underline width */
              margin: 1Spx auto;
              border-bottom: 5px solid black;
            }
            thead {
              display: table-header-group;
            }
            @media print {
              .header-message {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                padding: 10px 0;
                background: white;
                border-bottom: 1px solid black;
              }
              table {
                page-break-inside: auto;
              }
              tr {
                page-break-inside: avoid;
                page-break-after: auto;
              }
            }
          </style>
        </head>
        <body>
          ${message}
          <table>
            ${tableHeader}
            <tbody>${printContent}</tbody>
          </table>
        </body>
      </html>
    `);

    newWindow.document.close();
    newWindow.print();
  };

  const filteredItems = settlements.filter((item) => {
    if (paymentStatus === '') return true;
    return item.paymenystatus === paymentStatus;
  });

  const calculateTotals = (settlements) => {
    console.log('calculateTotals', settlements);
    let totalInvestmentAmount = 0;
    let totalPayableAmount = 0;
  
    settlements.forEach((payment) => {
      console.log(  'payment', payment);
      
      totalInvestmentAmount += parseFloat(payment.investmentAmount )|| 0;
      totalPayableAmount += parseFloat(payment.payableAmount) || 0;
    });
  
    return {
      totalInvestmentAmount,
      totalPayableAmount,
    };
  };
  
  const totals = calculateTotals(settlements);

console.log('totals', totals);

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <h2
        style={{
          color: '#FFFFFF',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          fontWeight: 'bold',
          fontSize: '2rem',
          marginLeft: '20px',
          marginTop: '20px',
        }}
      >
        Investment Payment Report
      </h2>

      <div style={{ marginLeft: '20px', display: 'flex', gap: '3%' }}>
        <button
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            marginTop: '20px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '4px',
          }}
          onClick={handlePrint}
        >
          Print
        </button>
        {/* <button
          style={{
            backgroundColor: "#2196F3",
            color: "white",
            padding: "10px 20px",
            marginTop: "20px",
            cursor: "pointer",
            border: "none",
            borderRadius: "4px",
          }}
          onClick={handleUpdate}
        >
          Update
        </button> */}
      </div>

      {/* Input Fields for Payment Type and Amount */}
      <div
        style={{
          margin: '20px',
          padding: '10px',
          backgroundColor: '#333',
          borderRadius: '5px',
          display: 'flex',
          gap: '5%',
        }}
      >
        {/* <h3 style={{ color: "#fff" }}>Payment Details</h3> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <label style={{ color: '#fff' }}>Payment Date</label>
          <input
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            style={{ width: '100%', padding: '8px', margin: '10px 0' }}
          /> */}
          <div style={{ margin: '20px', display: 'flex', gap: '10px' }}>
            <div>
              <label style={{ color: '#fff' }}>Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ width: '100%', padding: '8px', margin: '10px 0' }}
              />
            </div>
            <div>
              <label style={{ color: '#fff' }}>End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ width: '100%', padding: '8px', margin: '10px 0' }}
              />
            </div>
          </div>
        </div>
       
        {/* <div style={{ display: 'flex', flexDirection: 'column', marginTop:"1.5%" }}>
          <label style={{ color: '#fff' }}>Payment Type</label>
          <select
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            style={{ width: '100%', padding: '8px', margin: '10px 0' }}
          >
            <option value="">Select Payment Type</option>
            <option value="fixed">Fixed Amount</option>
            <option value="percentage">Percentage</option>
          </select>
          {paymentType === 'fixed' && (
            <>                  
              <label style={{ color: '#fff' }}>Paid Amount (Fixed)</label>
              <input
                type="number"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(Number(e.target.value))}
                style={{ width: '100%', padding: '8px', margin: '10px 0' }}
              />
            </>
          )}
        </div> */}
        {/* <div style={{ margin: '20px', padding: '10px', backgroundColor: '#333', borderRadius: '5px', display: 'flex', flexDirection: 'column', marginTop:'1.5%' }}>
          <label style={{ color: '#fff', marginRight: '10px' }}>Select Leader:</label>
          <select
            value={selectedLeader}
            onChange={(e) => setSelectedLeader(e.target.value)}
            style={{
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              color: '#333',
              border: '1px solid #ddd',
            }}
          >
            <option value="">All Leaders</option>
            {leaders.map((leader, index) => (
              <option key={index} value={leader}>
                {leader}
              </option>
            ))}
          </select>
        </div> */}
      </div>

      <div
        style={{ overflowX: 'auto', maxWidth: '100%', padding: '20px' }}
        ref={tableRef}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            height: '10%',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>SL. No</th>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>BV ID</th>
              <th style={tableHeaderStyle}>Name</th>
              {/* <th style={tableHeaderStyle}>Recommended Leader Name</th> */}
              <th style={tableHeaderStyle}>investmentAmount</th>
              {/* <th style={tableHeaderStyle}>Approved Amount</th> */}
              <th style={tableHeaderStyle}>Payable Amount</th>
              <th style={tableHeaderStyle}>Signature</th>
              {/* <th style={tableHeaderStyle}>Remaining Balance</th> */}
            </tr>
          </thead>
          <tbody>
            {settlements.map((settlement, i) => (
              <tr key={settlement.id}>
                <td style={tableCellStyle}>{i + 1}</td>
                <td style={tableCellStyle}>
                  {new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(settlement.timestamp.seconds * 1000))}
                </td>
                <td style={tableCellStyle}>{settlement.memberBVid}</td>
                <td style={tableCellStyle}>{settlement.memberName}</td>
                {/* <td style={tableCellStyle}>{settlement.leaderName}</td> */}
                <td style={tableCellStyle}>{settlement.investmentAmount}</td>
                {/* <td style={tableCellStyle}>{settlement.approvedamount}</td> */}
                <td style={tableCellStyle}>{settlement.payableAmount}</td>
                <td
                  style={{
                    ...tableCellStyle,
                    width: '230px', // Adjust width
                    height: '140px', // Adjust height
                    textAlign: 'center', // Optional: Center content
                  }}
                ></td>
                {/* <td
                  style={{
                    ...tableCellStyle,
                    width: '20px', // Adjust width
                    height: '140px', // Adjust height
                    textAlign: 'center', // Optional: Center content
                  }}
                >
                  {settlement.remainingBalance}
                </td> */}
              </tr>
            ))}
          </tbody>
          <tfoot>
          <tr>
            <td colSpan="4" style={tableCellStyle}>Total</td>
            <td style={tableCellStyle}>{totals.totalInvestmentAmount.toFixed(2)}</td>
            <td style={tableCellStyle}>{totals.totalPayableAmount.toFixed(2)}</td>
            <td style={tableCellStyle}></td>
          </tr>
        </tfoot>
        </table>
      </div>
    </div>
  );
}

const tableHeaderStyle = {
  backgroundColor: 'rgb(222 221 221)',
  color: 'black',
  padding: '8px',
  border: '1px solid black',
};

const tableCellStyle = {
  padding: '8px',
  border: '1px solid black',
};

export default InvestmentPaymentReport;
