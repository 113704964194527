import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Sheet,
  Card,
  CardContent,
  Button,
  Modal,
  Chip,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import SupportSystem from "../support/SupportSystem";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { db } from "../../firebase";
import QRCode from "react-qr-code"; // Import QRCode component
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { updateDoc, doc } from "firebase/firestore";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import { useGenealogyTree } from "../../context/TreeContext";
import { useBalance } from "../../context/BalanceContext";

function Dashboard() {
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [myClients, setMyClients] = useState(0);
  const { treeData, setTreeData } = useGenealogyTree();
  const { user } = useAuth();
  const userId = user.uid;
  const [messages, setMessages] = useState([]);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState(0);
  const [curentUserData, setCurentUserData] = useState([]);
  const [kycVerification, setKycVerification] = useState([]);
    const { latestBalance } = useBalance();
  const tenantsCollection = collection(db, "members");
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log('curentUserData', user, kycVerification);

  useEffect(() => {
    const loadTree = async () => {
      if (!curentUserData || !curentUserData.BVID) return;

      try {
        const members = await fetchMemberse();

        const currentUser = members.find(
          (member) => member.BVID === curentUserData.BVID
        );

        if (!currentUser) {
          throw new Error('Current user data not found');
        }

        const tree = {
          name: currentUser.name,
          attributes: { BVID: currentUser.BVID },
          children: buildTree(members, currentUser.BVID),
        };

        // Store tree data in the context
        setTreeData(tree);
      } catch (error) {
        console.error('Error fetching tree data:', error);
      } finally {
        // setLoading(false);
      }
    };
    loadTree();
  }, [curentUserData, setTreeData]);

  const fetchMemberse = async () => {
    const querySnapshot = await getDocs(collection(db, 'members'));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const buildTree = (members, parentId) => {
    const children = members
      .filter((member) => member.parentId === parentId)
      .map((child) => ({
        name: child.name,
        attributes: { BVID: child.BVID },
        children: buildTree(members, child.BVID) || null,
      }));
    return children.length > 0 ? children : null;
  };

  // Recursive function to count total nodes in the tree
  const countNodes = (node) => {
    if (!node) return 0; // If the node is null, return 0
    if (!Array.isArray(node.children) || node.children.length === 0) return 1; // If no children, count the node itself
    return 1 + node.children.reduce((sum, child) => sum + countNodes(child), 0); // Count current node and all children
  };

  // Calculate the total length of the tree
  const totalNodes = treeData ? countNodes(treeData) - 1 : 0;

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where("email", "==", user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, "messages");
        const q = query(messagesRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const fetchedMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(fetchedMessages);

        // Calculate unseen messages count
        const unseenCount = fetchedMessages.filter((msg) => !msg.seen).length;
        setUnseenMessagesCount(unseenCount);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [db]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    markMessagesAsSeen(); // Optionally mark all messages as seen
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const markMessagesAsSeen = async () => {
    const unseenMessages = messages.filter((msg) => !msg.seen);
    try {
      for (const message of unseenMessages) {
        const messageRef = doc(db, "messages", message.id);
        await updateDoc(messageRef, { seen: true });
      }
      setUnseenMessagesCount(0); // Reset unseen count after marking as seen
    } catch (error) {
      console.error("Error updating message status:", error);
    }
  };

  const parentid = curentUserData.BVID;
  const referralLink = `https://briarv.com/signup?ref=${userId}&parentid=${parentid}`;
  // Ensure curentUserData and BVID are defined
  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const bvId = curentUserData?.BVID;
        if (!bvId) {
          return;
        }

        // Get a reference to the members collection
        const membersRef = collection(db, "members");

        // Query to fetch members where parentId equals the current user's BVID
        const myClientsQuery = query(membersRef, where("parentId", "==", bvId));
        const myClientsSnapshot = await getDocs(myClientsQuery);

        // Create an array to hold the matched members
        const matchedMembers = myClientsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Update state with the matched members and count
        setMyClients(matchedMembers.length);
      } catch (error) {
        console.error("Error fetching members data:", error);
      }
    };

    if (curentUserData?.BVID) {
      fetchMembersData(); // Only call if BVID exists
    }
  }, [curentUserData]); // Depend on curentUserData

  //kyc data fatch 
  useEffect(() => {
    const fetchKycVerification = async () => {
      try {
        const kycVerificationsRef = collection(db, "kycVerifications");
        const q = query(kycVerificationsRef, where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const kycData = querySnapshot.docs[0].data();
          setKycVerification({ id: user.uid, ...kycData });
        }
      } catch (error) {
        console.error("Error fetching KYC verification:", error);
      }
    };

    fetchKycVerification();
  }, [user.uid]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Query for liveAccountRequests where createdBy matches userId and status is "Accepted"
        const investmentQuery = query(
          collection(db, "liveAccountRequests"),
          where("createdUserId", "==", userId), // Filter by userId
          where("status", "==", "Accepted") // Filter by status
        );

        const investmentSnapshot = await getDocs(investmentQuery);
        let totalInvestment = 0;

        // Iterate through the filtered documents and sum up the investment amounts
        investmentSnapshot.forEach((doc) => {
          const data = doc.data();
          // Convert investmentAmount from string to number and add to totalInvestment
          totalInvestment += parseFloat(data.investmentAmount) || 0;
        });

        // Update the state with the calculated total investment
        setInvestmentAmount(totalInvestment);

        // Fetch and count all documents in the 'members' collection
        const membersSnapshot = await getDocs(collection(db, "members"));
        setTotalClients(membersSnapshot.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]); // Add userId as a dependency if it might change


  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success("Copied to clipboard");
  };

  return (
    <>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <Typography
            level="h2"
            style={{
              color: "#FFFFFF", // White text color
              fontWeight: "lg", // Font weight set to large
              fontSize: "2rem", // Increased font size
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adding a subtle shadow
            }}
          >
            Dashboard
          </Typography>
          {/* <Typography
            level="h2"
            style={{
              color: "#FFFFFF", // White text color
              fontWeight: "lg", // Font weight set to large
              fontSize: "1rem", // Increased font size
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Adding a subtle shadow
            }}
          >
            {investmentAmount > 0 ? (
                  // <Chip label="Active Account" color="success" />
                  <h6 style={{color:"green"}}>Acctive</h6>
                ) : (
                  // <Chip label="Inactive Account" color="error" />
                  <h6 style={{color:"red"}}>Inactive</h6>
                )}
          </Typography> */}


          {/* <div onClick={handleOpenModal} style={{marginRight:"7%"}}>
            <NotificationsActiveIcon sx={{ color: 'red', cursor: 'pointer' }} />
            {unseenMessagesCount > 0 && (
              <span
                style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}
              >
                {unseenMessagesCount} new
              </span>
            )}
          </div> */}
        </div>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
            backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
            boxShadow: "none", // Optional: Removes the shadow for a cleaner look
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
              }}
            >
              <div style={{ display: 'flex', }}>
                <CardContent>
                  <Typography
                    level="h5"
                    fontWeight="lg"
                    sx={{ marginBottom: 1, color: "White" }}
                  >
                    Invest Amount
                  </Typography>
                  <Typography level="body1" sx={{ color: "White" }}>
                    Volume: ${investmentAmount}
                  </Typography>
                  <Typography level="body1" sx={{ color: "White" }}>
                    {/* This Month: ${investmentAmount} */}
                  </Typography>
                </CardContent>
              </div>
            </Card>

            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: "md",
                // border: "1px solid red",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  My Direct Clients
                </Typography>
                <Typography level="body1" sx={{ color: "White" }}>
                  My Clients: {myClients}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  My Total Group
                </Typography>
                <Typography level="body1" sx={{ color: "White" }}>
                  Group Size: {totalNodes}
                </Typography>
                {/* <Typography level="body1" sx={{ color: 'black' }}>
                  Growth Rate: 5%
                </Typography> */}
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Card
              sx={{
                height: "110px",
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  Wallet Balance
                </Typography>
                <Typography level="body1" sx={{ color: "White" }}>
                  {/* Volume: ${investmentAmount} */}
                  Volume: ${latestBalance}
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                height: "110px",
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
                display: "flex",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  Status
                </Typography>
                <Typography sx={{ color: "black" }}>
                  {/* Volume: ${investmentAmount} */}

                  {investmentAmount > 0 ? (
                    // <Chip label="Active Account" color="success" />
                    <h6 style={{ color: "#57ff57", fontSize: '1.5rem', fontWeight: "6rem" }}>Active</h6>
                  ) : (
                    // <Chip label="Inactive Account" color="error" />
                    <h6 style={{ color: "red", fontSize: '1.5rem', fontWeight: "6rem" }}>Inactive</h6>
                  )}

                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                height: "110px",
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
                display: "flex",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  KYC Verification
                </Typography>
                <Typography sx={{
                  color: kycVerification ?
                    (kycVerification.status === "Accepted" ? "#57ff57" :
                      kycVerification.status === "Pending" ? "red" : "black")
                    : "black",
                  fontSize: '1.5rem',
                  fontWeight: "600"
                }}>
                  {kycVerification ? kycVerification.status : "No KYC Verification found"}

                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                height: "110px",
                flex: 1,
                padding: 2,
                borderRadius: "md",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                maxWidth: { xs: "100%", sm: "48%" },
                background: "#1a2a3385",
                color: "#fff", // White text for contrast
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <Typography
                  level="h5"
                  fontWeight="lg"
                  sx={{ marginBottom: 1, color: "White" }}
                >
                  Fund Wallet
                </Typography>
                <Typography level="body1" sx={{ color: "White" }}>
                  {/* Volume: ${investmentAmount} */}
                  Volume: $0
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              // backgroundColor: "#2C3E50",
              padding: 3,
              borderRadius: "sm",
              // color: "#FFFFFF",
              width: "100%",
              flex: 1,
              padding: 2,
              // borderRadius: "md",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
              // maxWidth: { xs: "100%", sm: "48%" },
              background: "#1a2a3385",
              color: "#fff", // White text for contrast
              borderRadius: "12px",
              // maxWidth: 600,
            }}
          >
            <Typography
              level="body1"
              fontWeight="lg"
              sx={{
                mb: 2,
                textAlign: "center",
                fontSize: "22px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              Use Your Unique Referral link to invite Others!
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "md",
                backgroundColor: "#1a2a339c",
                padding: 1,
                width: "100%",
                border: "1px solid #98999a",
              }}
            >
              <Typography
                level="body1"
                sx={{
                  color: "#FFFFFF",
                  mr: 2,
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
              >
                {referralLink}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCopyLink}
                sx={{ marginTop: { xs: 2, sm: 0 } }}
              >
                <ContentCopyIcon />
              </Button>
            </Box>
            <Box sx={{ mt: 2, mb: 2, textAlign: "center" }}>
              <Typography level="body2" fontWeight="md" sx={{ mb: 1 }}>
                Scan the QR code below to access your referral link:
              </Typography>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: "16px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <QRCode value={referralLink} size={128} />
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              marginTop: 2,
              padding: "12px 20px",
              borderRadius: "10px",
              backgroundColor: "#F1F1F1",
              color: "#333",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              width: "100%",
              maxWidth: "300px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            " Market will be closed tomorrow.. Stay tune and follow us ."
          </Box> */}
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                // border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <Typography level="h6" fontWeight="lg" sx={{ marginBottom: 2 }}>
                Notifications
              </Typography>
              {messages.length === 0 ? (
                <Typography>No messages available.</Typography>
              ) : (
                messages.map((msg) => (
                  <Card
                    key={msg.id}
                    sx={{ marginBottom: 2, backgroundColor: "#f5f5f5" }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: "14px", marginBottom: "4px" }}
                      >
                        {new Date(
                          msg.createdAt?.seconds * 1000
                        ).toLocaleString()}
                      </Typography>
                      <Typography level="h6" fontWeight="lg">
                        {msg.title}
                      </Typography>
                      <Typography>{msg.message}</Typography>
                    </CardContent>
                  </Card>
                ))
              )}
            </Box>
          </Modal>
        </Sheet>
        <SupportSystem />
      </Box>
    </>
  );
}

export default Dashboard;
