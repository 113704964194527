import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { db } from '../../firebase'; // Firebase configuration import
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import TrcWithdrawal from '../withdrawal/TrcWithdrawal';
import { useBalance } from '../../context/BalanceContext';

function Wallet() {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [investmentWithdrawals, setInvestmentWithdrawals] = useState([]);
  const [combinedTransactions, setCombinedTransactions] = useState([]);
  // const [latestBalance, setLatestBalance] = useState(0);
  const { latestBalance, setLatestBalance } = useBalance();
console.log('userWA', latestBalance);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid;

        const q = query(
          collection(db, 'investmentPayments'),
          where('memberUId', '==', currentUserUid)
        );

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTransactions(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    const fetchInvestmentWithdrawals = async () => {
      try {
        const investmentWithdrawalsCollection = collection(
          db,
          'investmentPaymentWithdrawal'
        );
        const q = query(
          investmentWithdrawalsCollection,
          where('memberUId', '==', user.uid),
          where('withdrawalStatus', '==', 'accepted'),
          where('type', '==', 'debit')
        );
        const querySnapshot = await getDocs(q);
        const withdrawalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInvestmentWithdrawals(withdrawalsData);
      } catch (error) {
        console.error('Error fetching investment withdrawals: ', error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchSettlements(), fetchInvestmentWithdrawals()]);
      setLoading(false);
    };

    fetchData();
  }, [user.uid]);

  useEffect(() => {
    const combined = [...transactions, ...investmentWithdrawals];

    // Sort combined transactions in ascending order for correct balance calculation
    combined.sort(
      (a, b) =>
        new Date(a.timestamp.seconds * 1000) -
        new Date(b.timestamp.seconds * 1000)
    );

    setCombinedTransactions(combined);
     // Calculate latest balance
  const balance = combined.reduce((acc, transaction) => {
    if (transaction.type === 'credit') {
      return acc + parseFloat(transaction.payableAmount);
    } else if (transaction.type === 'debit') {
      return acc - parseFloat(transaction.payableAmount);
    }
    return acc;
  }, 0);

  setLatestBalance(balance);
  }, [transactions, investmentWithdrawals]);

  const calculateBalance = (transactions) => {
    let balance = 0;
    return transactions.map((transaction) => {
      if (transaction.type === 'credit') {
        balance += parseFloat(transaction.payableAmount);
      } else if (transaction.type === 'debit') {
        balance -= parseFloat(transaction.payableAmount);
      }
      return { ...transaction, balance };
    });
  };

  const transactionsWithBalance = calculateBalance(combinedTransactions);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{
          fontSize: { xs: '1.5rem', md: '2rem' },
          textAlign: 'left',
          marginTop: 6,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)',
          marginLeft: 3,
        }}
      >
        Withdraw Wallet
      </Typography>
      <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '10px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            marginTop: '1rem',
            backgroundColor: 'rgba(51, 51, 51, 0.7)',
            borderRadius: 8,
          }}
        >
          <thead>
            <tr>
              <td style={tableHeaderStyle}>Date</td>
              <td style={tableHeaderStyle}>Remarks</td>
              <td style={tableHeaderStyle}>Debit</td>
              <td style={tableHeaderStyle}>Credit</td>
              <td style={tableHeaderStyle}>Balance</td>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
            {[...transactionsWithBalance].reverse().map((item) => (
              <tr
                key={item.id}
                style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}
              >
                <td style={tableCellStyle}>{formatDate(item.timestamp)}</td>
                <td style={tableCellStyle}>{item.message}</td>
                <td style={tableCellStyle}>
                  {item.type === 'debit' ? item.payableAmount : '0.00'}
                </td>
                <td style={tableCellStyle}>
                  {item.type === 'credit' ? item.payableAmount : '0.00'}
                </td>
                <td style={tableCellStyle}>{item.balance.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  textAlign: 'left',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: 'white',
  borderRadius: 8,
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #555',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'black',
};

export default Wallet;