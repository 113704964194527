import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Select,
  MenuItem,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import { db } from '../../firebase'; // Import Firebase setup
import SupportSystem from '../support/SupportSystem';
import { addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { useAuth } from '../../context/AuthContext';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

function OldSettleMent() {
  // State to handle form inputs
  const { user } = useAuth();
  const navigate = useNavigate();
  const storage = getStorage();
  const [leaderOptions, setLeaderOptions] = useState([]);
  const [members, setMembers] = useState([]); // To store all member data
  const [searchValue, setSearchValue] = useState(''); // For search input
  const [selectedName, setSelectedName] = useState('');
  const [selectedId, setSearchId] = useState('');
  const [paymentType, setPaymentType] = useState('fixed');
  const [formData, setFormData] = useState({
    ClinName: '',
    mobileNo: '',
    alternativeMobileNo: '',
    SdfxId: '',
    password: '',
    newPassword: '',
    amount: '',
    balance: '',
    approvedamount: '',
    percentage: '',
    dashboardImage: '',
    walletImage: '',
    remainingBalance: 0,
    paymentType: 'fixed',
  });
  console.log('formData:', formData);

  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const docRef = doc(db, 'oldSettlement', id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setFormData(data);

            // Set searchValue and selectedName from leaderId and leaderName
            setSearchValue(data.leaderId || '');
            setSelectedName(data.leaderName || '');
            setPaymentType(data.paymentType || 'fixed');
          } else {
            toast.error('Document not found.');
          }
        } catch (error) {
          console.error('Error fetching document: ', error);
          toast.error('Failed to load data.');
        }
      }
    };

    fetchData();
  }, [id]);


  const fetchMembers = async () => {
    try {
      const memberCollection = collection(db, 'members');
      const querySnapshot = await getDocs(memberCollection);
      const fetchedMembers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMembers(fetchedMembers);
    } catch (error) {
      console.error('Error fetching members:', error);
      toast.error('Failed to fetch members');
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === 'dashboardImage' || name === 'walletImage') {
      const file = files[0];
      if (file) {
        try {
          // Determine the storage folder dynamically based on input name
          const imageRef = ref(storage, `images/${name}/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);

          // Update formData with the correct URL for front, back, or holding image
          setFormData((prevData) => ({
            ...prevData,
            [name === 'dashboardImage' ? 'dashboardImage' : 'walletImage']:
              downloadURL,
          }));

          toast.success(
            `${name === 'dashboardImage' ? 'dashboardImage' : 'walletImage'
            } Image uploaded successfully!`
          );
        } catch (error) {
          console.error('Error uploading image:', error);
          toast.error('Failed to upload image');
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     // Add form data to Firestore under the "oldSettlement" collection
  //     await addDoc(collection(db, 'oldSettlement'), {
  //       ...formData,
  //       amount: formData.amount === "" ? 0 : parseFloat(formData.amount),
  //       balance: formData.balance === "" ? 0 : parseFloat(formData.balance),
  //       approvedamount: formData.approvedamount === "" ? 0 : parseFloat(formData.approvedamount),
  //       percentage: formData.percentage === "" ? 0 : parseFloat(formData.percentage),
  //       leaderName: selectedName,
  //       leaderId: searchValue,
  //       status: 'pending',
  //       timestamp: new Date(),
  //     });
  //     toast.success('Data saved successfully!');
  //     setFormData({
  //       ClinName: '',
  //       mobileNo: '',
  //       alternativeMobileNo: '',
  //       SdfxId: '',
  //       password: '',
  //       newPassword: '',
  //       amount: '',
  //       balance: '',
  //       approvedamount: '',
  //       percentage: '',
  //       dashboardImage: '',
  //       walletImage: '',
  //     });
  //     navigate('/oldsettlementview');
  //   } catch (error) {
  //     console.error('Error saving data: ', error);
  //     alert('Error saving data.');
  //   }
  // };

  const handlePaymentTypeChange = (event) => {
    const { value } = event.target;
    setPaymentType(value);
    if (value === 'fixed') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        percentage: '',
        paymentType: 'fixed',
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        paymentType: 'percentage',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id) {
        // If the ID is present, we are updating the existing document
        const docRef = doc(db, 'oldSettlement', id);
        await updateDoc(docRef, {
          ...formData,
          amount: formData.amount === "" ? 0 : parseFloat(formData.amount),
          balance: formData.balance === "" ? 0 : parseFloat(formData.balance),
          approvedamount: formData.approvedamount === "" ? 0 : parseFloat(formData.approvedamount),
          percentage: formData.percentage === "" ? 0 : parseFloat(formData.percentage),
          leaderName: selectedName,
          leaderId: searchValue,
          status: 'pending',  // Make sure you want to keep 'pending' here, or pass an updated status
          timestamp: new Date(),
          remainingBalance: 0,
        });
        toast.success('Data updated successfully!');
      } else {
        // If no ID, it's a new entry, add to Firestore
        await addDoc(collection(db, 'oldSettlement'), {
          ...formData,
          amount: formData.amount === "" ? 0 : parseFloat(formData.amount),
          balance: formData.balance === "" ? 0 : parseFloat(formData.balance),
          approvedamount: formData.approvedamount === "" ? 0 : parseFloat(formData.approvedamount),
          percentage: formData.percentage === "" ? 0 : parseFloat(formData.percentage),
          leaderName: selectedName,
          leaderId: searchValue,
          timestamp: new Date(),
          remainingBalance: 0,
        });
        toast.success('Data saved successfully!');
      }

      // Clear the form and navigate
      setFormData({
        ClinName: '',
        mobileNo: '',
        alternativeMobileNo: '',
        SdfxId: '',
        password: '',
        newPassword: '',
        amount: '',
        balance: '',
        approvedamount: '',
        percentage: '',
        dashboardImage: '',
        walletImage: '',
        remainingBalance: '',
        paymentType: 'fixed',
      });
      navigate('/oldsettlementview');
    } catch (error) {
      console.error('Error saving data: ', error);
      alert('Error saving data.');
    }
  };


  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0, // Places the image behind the content
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box
          sx={{
            padding: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#40474F',
            width: '100%',
          }}
        >
          <Typography
            level="h2"
            style={{ color: '#FFFFFF' }}
            fontWeight="lg"
            sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, textAlign: 'center' }}
          >
            Old Settlements
          </Typography>
          <Sheet
            variant="soft"
            sx={{
              padding: { xs: 2, sm: 3 },
              borderRadius: 'sm',
              width: '100%',
              marginTop: 2,
              marginX: 'auto',
              bgcolor: 'neutral.softHoverBg',
              backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent white
              backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
              boxShadow: 'none',
            }}
          >
            <Button
              onClick={() => navigate('/oldsettlementview')}
              variant="soft"
              sx={{
                // marginTop: 3,
                backgroundColor: 'red',
                color: '#FFFFFF',
              }}
            >
              <ReplyAllIcon />
            </Button>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%',
                }}
              >
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                    marginTop: 2,
                  }}
                >
                  <FormLabel>Leader ID</FormLabel>
                  <Input
                    type="text"
                    placeholder="Enter BVID"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />

                  {searchValue && (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {members
                        .filter((member) => member.BVID.includes(searchValue))
                        .map((member) => (
                          <li
                            key={member.id}
                            style={{ cursor: 'pointer', margin: '5px 0' }}
                            onClick={() => {
                              setSearchValue(member.BVID); // Clear the search field
                              setSearchId(member.BVID); // Clear the search field
                              setSelectedName(member.name); // Set the selected name
                            }}
                          >
                            {member.BVID} - {member.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </FormControl>
                {selectedName && (
                  <FormControl
                    sx={{
                      flex: 1,
                      minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                      padding:1,
                      marginTop: 1,
                    }}
                  >
                    <FormLabel>Leader Name</FormLabel>
                    <Input
                      placeholder="Enter Leader Name"
                      type="text"
                      // name="leaderName"
                      value={selectedName}
                      // onChange={handleChange}
                      readOnly
                    />
                  </FormControl>
                )}
              </Box>
            </form>
          </Sheet>
          <Sheet
            variant="soft"
            sx={{
              padding: { xs: 2, sm: 3 },
              borderRadius: 'sm',
              width: '100%',
              marginTop: 2,
              marginX: 'auto',
              bgcolor: 'neutral.softHoverBg',
              backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent white
              backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
              boxShadow: 'none',
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%',
                }}
              >
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Name of Invester</FormLabel>
                  <Input
                    placeholder="Enter Name"
                    type="text"
                    name="ClinName"
                    value={formData.ClinName}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Mobile No.</FormLabel>
                  <Input
                    placeholder="Enter Mobile No."
                    type="number"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Alternative Mobile No.</FormLabel>
                  <Input
                    placeholder="Enter your Alternative Mobile No."
                    type="text"
                    name="alternativeMobileNo"
                    value={formData.alternativeMobileNo}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Old Investment ID</FormLabel>
                  <Input
                    placeholder="Enter your ID"
                    type="text"
                    name="SdfxId"
                    value={formData.SdfxId}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Password</FormLabel>
                  <Input
                    placeholder="Enter your Password"
                    type="text"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>New Password</FormLabel>
                  <Input
                    placeholder="Enter your New Password"
                    type="text"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Amount</FormLabel>
                  <Input
                    placeholder="Enter Amount"
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Approved Amount</FormLabel>
                  <Input
                    placeholder="Enter Approved Amount"
                    type="number"
                    name="approvedamount"
                    value={formData.approvedamount}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Balance</FormLabel>
                  <Input
                    placeholder="Enter Balance"
                    type="number"
                    name="balance"
                    value={formData.balance}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Payment Type</FormLabel>
                  <select
                    name="paymentType"
                    value={paymentType}
                    onChange={handlePaymentTypeChange}
                    style={{
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      width: '100%',
                      backgroundColor: '#fff',
                      fontSize: '16px'
                    }}
                  >
                    <option value="fixed">Fixed</option>
                    <option value="percentage">Percentage</option>
                  </select>
                </FormControl>
                {paymentType === 'percentage' && (
                  <FormControl
                    sx={{
                      flex: 1,
                      minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                    }}
                  >
                    <FormLabel>Percentage</FormLabel>
                    <Input
                      placeholder="Enter Percentage"
                      type="number"
                      name="percentage"
                      value={formData.percentage}
                      onChange={handleChange}
                    />
                  </FormControl>
                )}
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Dashboard Image</FormLabel>
                  <Input
                    placeholder="Enter Leader Name"
                    type="file"
                    name="dashboardImage"
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Wallet Image</FormLabel>
                  <Input
                    placeholder="Enter Name"
                    type="file"
                    name="walletImage"
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="solid"
                  color="danger"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                  onClick={() => navigate('/oldsettlementview')}
                >
                  Cancle
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="success"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  {id ? 'Update' : 'Submit'}
                </Button>
              </Box>
            </form>
          </Sheet>
          <SupportSystem />
        </Box>
      </CssVarsProvider>
    </div>
  );
}

export default OldSettleMent;
