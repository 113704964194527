import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { db } from '../../firebase'; // Firebase configuration import
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

function FundWallet() {
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [investamount, setInvestAmount] = useState([]);
  const [liveAccountRequests, setLiveAccountRequests] = useState([]);
  const [fundBalance, setFundBalance] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log('userWA', user, settlements, combinedData);

  // useEffect(() => {
  //   const fetchSettlements = async () => {
  //     try {
  //       const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

  //       // Fetch investmentPayments data
  //       const investmentPaymentsQuery = query(
  //         collection(db, 'investmentPayments'),
  //         where('memberUId', '==', currentUserUid)
  //       );

  //       const investmentPaymentsSnapshot = await getDocs(
  //         investmentPaymentsQuery
  //       );
  //       const investmentPaymentsData = investmentPaymentsSnapshot.docs.map(
  //         (doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         })
  //       );

  //       // Fetch liveAccountRequests data
  //       const liveAccountRequestsQuery = query(
  //         collection(db, 'liveAccountRequests'),
  //         where('createdUserId', '==', currentUserUid),
  //         where('status', '==', 'Accepted')
  //       );

  //       const liveAccountRequestsSnapshot = await getDocs(
  //         liveAccountRequestsQuery
  //       );
  //       const liveAccountRequestsData = liveAccountRequestsSnapshot.docs.map(
  //         (doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         })
  //       );

  //       // Combine the results
  //       const paymentData = [...investmentPaymentsData];
  //       const requsetData = [...liveAccountRequestsData];
  //       setSettlements(paymentData);
  //       setInvestAmount(requsetData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching data: ', error);
  //       toast.error('Error fetching data.');
  //       setLoading(false);
  //     }
  //   };

  //   fetchSettlements();
  // }, [user]);

  useEffect(() => {
    const fetchLiveAccountRequests = async () => {
      try {
        const liveAccountRequestsCollection = collection(
          db,
          'liveAccountRequests'
        );
        const q = query(
          liveAccountRequestsCollection,
          where('createdUserId', '==', user.uid),
          where('status', '==', 'Accepted'),
          // where('type', '==', 'credit')
        );
        const querySnapshot = await getDocs(q);
        const liveAccountRequestsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLiveAccountRequests(liveAccountRequestsData);
      } catch (error) {
        console.error('Error fetching live account requests: ', error);
      }
    };

    const fetchFundBalance = async () => {
      try {
        const fundBalanceCollection = collection(db, 'fundBalance');
        const q = query(
          fundBalanceCollection,
          where('createdUserId', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const fundBalanceData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFundBalance(fundBalanceData);
      } catch (error) {
        console.error('Error fetching fund balance: ', error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchLiveAccountRequests();
      await fetchFundBalance();
      setLoading(false);
    };

    fetchData();
  }, [user.uid]);

  useEffect(() => {
    const combined = [...liveAccountRequests, ...fundBalance];
  
    combined.sort((a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt || 0); // Use updatedAt first, fallback to createdAt
      const dateB = new Date(b.updatedAt || b.createdAt || 0);
      
      return dateB - dateA; // Sort in descending order (recent first)
    });
  
    setCombinedData(combined);
  }, [liveAccountRequests, fundBalance]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
    return date.toLocaleDateString('en-GB'); // Format date to DD/MM/YYYY
  };

  const calculateBalance = (transactions) => {
    let balance = 0;
    
    // First calculate balances in chronological order
    const chronological = [...transactions].reverse();
    const withBalances = chronological.map(transaction => {
      if (transaction.type === 'credit') {
        balance += parseFloat(transaction.investmentAmount || 0);
      } else if (transaction.type === 'debit') {
        balance -= parseFloat(transaction.investmentAmount || 0);
      }
      return { ...transaction, balance: Number(balance.toFixed(2)) };
    });
  
    // Reverse back to show most recent first
    return withBalances.reverse();
  };

  const transactionsWithBalance = calculateBalance(combinedData);
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{
          fontSize: { xs: '1.5rem', md: '2rem' },
          textAlign: 'left',
          marginTop: 6,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)',
          marginLeft: 3,
        }}
      >
        Fund Wallet
      </Typography>
      <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '10px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            marginTop: '1rem',
            backgroundColor: 'rgba(51, 51, 51, 0.7)',
            // border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: 8,
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                  borderRadius: 8,
                }}
              >
                Date
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Remarks
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Debit
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Credit
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Balance
              </td>
            </tr>
          </thead>
          <tbody
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)', // Semi-transparent white for tbody background
            }}
          >
            {transactionsWithBalance.map((item) => (
              <tr
                key={item.id}
                style={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.5)', // Light border for row separation
                }}
              >
                <td
                  style={{
                    ...tableCellStyle,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Add slight white transparency to each cell
                  }}
                >
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td
                  style={{
                    ...tableCellStyle,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {item.tModeType}
                </td>
                <td
                  style={{
                    ...tableCellStyle,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {item.type === 'debit' ? item.investmentAmount : '0.00'}
                </td>
                <td
                  style={{
                    ...tableCellStyle,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {item.type === 'credit' ? item.investmentAmount : '0.00'}
                </td>
                <td
                  style={{
                    ...tableCellStyle,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {item.balance}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  textAlign: 'left',
  backgroundColor: '#555',
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #555',
  backgroundColor: '#bcbcbc',
  color: 'black',
};

export default FundWallet;
