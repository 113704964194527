import { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  );
  const [hotel, setHotel] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('token') || null);

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = async (email, password) => {
    try {
      const signInResult = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (signInResult.user.accessToken) {
        setToken(signInResult.user.accessToken);
        sessionStorage.setItem('token', signInResult.user.accessToken);
        localStorage.setItem('user', JSON.stringify(signInResult.user)); // Store user in localStorage
        return signInResult.user;
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      throw error;
    }
  };
  
  const logout = () => {
    setToken(null);
    setUser(null);
    sessionStorage.removeItem('token');
    localStorage.removeItem('user'); // Remove user from localStorage
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setToken(currentUser?.accessToken);
      if (currentUser?.accessToken) {
        sessionStorage.setItem('token', currentUser.accessToken);
      } else {
        sessionStorage.removeItem('token');
      }
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.email);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const fullUser = {
            ...currentUser,
            ...userDocSnapshot.data(),
          };
          setUser(fullUser);
          localStorage.setItem('user', JSON.stringify(fullUser)); // Store updated user in localStorage
        } else {
          setUser(currentUser);
          localStorage.setItem('user', JSON.stringify(currentUser)); // Store user in localStorage
        }
      } else {
        localStorage.removeItem('user'); // Remove user if signed out
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const getMyHotel = async () => {
      if (user?.hotelId) {
        const docRef = doc(db, 'forexpro', user.hotelId);
        const hotelDocSnapshot = await getDoc(docRef);
        if (hotelDocSnapshot.exists()) {
          setHotel({
            id: user.hotelId,
            ...hotelDocSnapshot.data(),
          });
        }
      }
    };
    getMyHotel();
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, hotel, token, logout, signIn, signUp }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);
