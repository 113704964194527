import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Sheet,
  Button,
  Modal,
  Table,
  Input,
  FormControl,
  FormLabel,
} from '@mui/joy';
import { collection, getDocs, doc, updateDoc, query, where, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

const LiveBalanceRequestList = () => {
  const { user } = useAuth();
  const userId = user.uid;
  const [members, setMembers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [rateOfInterest, setRateOfInterest] = useState('');
  const [roiStatus, setRoiStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  
    const fetchMembers = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, 'liveAccountRequests')
        );
        const membersList = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((member) => member.status === 'Pending');
        setMembers(membersList);
      } catch (error) {
        console.error('Error fetching members from Firebase:', error);
        toast.error('Error fetching members from Firebase!');
      }
    };

    useEffect(() => {
    fetchMembers();
  }, []);

  const updateMemberStatus = async (id, newStatus) => {
    try {
      const memberRef = doc(db, 'liveAccountRequests', id);
      await updateDoc(memberRef, { status: newStatus });

      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === id ? { ...member, status: newStatus } : member
        )
      );
      toast.success(`Member ${id} status updated to ${newStatus}!`);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status!');
    }
  };

  const handleAccept = (memberId) => {
    const member = members.find((m) => m.id === memberId);
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  // const handleDone = async () => {
  //   if (!selectedMember) return;

  //   try {
  //     await updateDoc(doc(db, 'liveAccountRequests', selectedMember.id), {
  //       status: 'Accepted',
  //       ActiveStatus: 'Active',
  //       type: 'debit',
  //       rateOfInterest,
  //       roiStatus,
  //     });
  //     const fundBalanceRef = doc(
  //       db,
  //       'fundBalance',
  //       selectedMember.createdUserId
  //     );
  //     await updateDoc(fundBalanceRef, {
  //       status: 'accepted',
  //       investmentAmount: 0.0,
  //       type: 'credit',
  //     });
  //     toast.success('Member updated successfully!');
  //     setIsModalOpen(false);
  //     setSelectedMember(null);
  //     setRateOfInterest('');
  //     setRoiStatus('');
  //   } catch (error) {
  //     console.error('Error updating member: ', error);
  //     toast.error('Failed to update member.');
  //   }
  // };


  const handleDone = async () => {
    if (!selectedMember) return;
  
    try {
      // Update liveAccountRequests document
      await updateDoc(doc(db, 'liveAccountRequests', selectedMember.id), {
        status: 'Accepted',
        ActiveStatus: 'Active',
        type: 'debit',
        rateOfInterest,
        roiStatus,
        updatedAt: new Date(),
      });
  console.log(selectedMember.id);
  
      // Get the createdUserId from the selected member
      const { createdUserId, investmentAmount } = selectedMember;
  console.log('createdUserId', createdUserId);
  
   
      // Fetch all fundBalance documents where createdUserId matches selectedMember.id
      const fundBalanceQuery = query(
        collection(db, 'fundBalance'),
        where('createdUserId', '==', createdUserId)
      );
      const fundBalanceSnapshot = await getDocs(fundBalanceQuery);
      console.log('fundBalanceSnapshot', fundBalanceSnapshot);

      if (!fundBalanceSnapshot.empty) {
        const batch = writeBatch(db);
        console.log('batch',batch);
        
        fundBalanceSnapshot.forEach((doc) => {
          const fundBalanceRef = doc.ref;
          batch.update(fundBalanceRef, {
            status: 'Accepted',
            type: 'credit'
          });
        });
        await batch.commit();
      } else {
        toast.error('No matching fund balance found for this user.');
      }
      fetchMembers();
      toast.success('Member and fund balance updated successfully!');
      setIsModalOpen(false);
      setSelectedMember(null);
      setRateOfInterest('');
      setRoiStatus('');
    } catch (error) {
      console.error('Error updating documents: ', error);
      toast.error('Failed to update member and fund balance.');
    }
  };

  const handleReject = (id) => {
    updateMemberStatus(id, 'Rejected');
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              marginTop: 6,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            Account Balance Request List
          </Typography>
          <Sheet variant="soft">
            {/* Data Table */}
            <Table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                margin: '0 auto',
              }}
              sx={{
                overflow: { sm: 'hidden' },
              }}
            >
              <thead>
                <tr
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)', color: 'white' }}
                >
                  {/* <th style={{ padding: '8px', border: '1px solid white' }}>ID</th> */}
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Broker Name
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Payment By
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Payment Mode
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Payment Amount
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Status
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Type
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Image
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr key={member.id} style={{ color: 'black' }}>
                    {/* <td style={{ padding: '8px', border: '1px solid white' }}>{member.id}</td> */}
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.brokerId}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.createdBy}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.paymentModeId}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.investmentAmount}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.status}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.tModeType}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      <img
                        src={member.imageUrl}
                        alt="Member"
                        onClick={() => handleImageClick(member.imageUrl)}
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      />
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid white',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleAccept(member.id)}
                        style={{ margin: '0 4px' }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="danger"
                        onClick={() => handleReject(member.id)}
                        style={{ margin: '0 4px' }}
                      >
                        Reject
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </CssVarsProvider>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            padding: 2,
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '300px',
            margin: 'auto',
            marginTop: '20vh',
          }}
        >
          <Typography level="h6" mb={2}>
            Update Member
          </Typography>
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Rate of Interest:</FormLabel>
            <Input
              name="rateOfInterest"
              value={rateOfInterest}
              onChange={(e) => setRateOfInterest(e.target.value)}
              required
              type="number"
              style={{ background: '#1a2a3385', color: 'white' }}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>ROI Status:</FormLabel>
            <select
              name="roiStatus"
              value={roiStatus}
              onChange={(e) => setRoiStatus(e.target.value)}
              required
              style={{ background: '#1a2a3385', color: 'white' }}
            >
              <option value="">Select ROI Status</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDone}
            style={{ background: '#1a2a3385', color: 'white' }}
          >
            Done
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default LiveBalanceRequestList;
